
.burger-menu{
    display: none;
    z-index: 3;
    position: absolute;
    top: 15px;
    right: 20px;
}


.burger-checkbox {
    position: absolute;
    visibility: hidden;
    z-index: 3;
}

.burger {
    cursor: pointer;
    display: block;
    position: relative;
    border: none;
    background: transparent;
    width: 40px;
    height: 26px;
    margin: 0 auto;
    z-index: 3;
}

.burger::before,
.burger::after {
    content: '';
    left: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 10px;
    background: var(--white);
    z-index: 3;
}

.burger::before {
    top: 0;
    box-shadow: 0 11px 0 var(--white);
    transition: box-shadow .3s .15s, top .3s .15s, transform .3s;
    z-index: 3;
}

.burger::after {
    bottom: 0;
    transition: bottom .3s .15s, transform .3s;
    z-index: 3;
}

.burger-checkbox:checked + .burger::before {
    top: 11px;
    transform: rotate(45deg);
    box-shadow: 0 6px 0 rgba(0, 0, 0, 0);
    transition: box-shadow .15s, top .3s, transform .3s .15s;
    z-index: 3;
}

.burger-checkbox:checked + .burger::after {
    bottom: 11px;
    transform: rotate(-45deg);
    transition: bottom .3s, transform .3s .15s;
    z-index: 3;
}


@media (max-width: 961px) {

    .burger-menu{
        display: flex;
        justify-content: center;
    }
}