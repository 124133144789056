.feedback {
    background: var(--wine);
    height: fit-content;
    width: 100%;
    z-index: 1;
    position: relative;
}

.feedback > svg{
    position: absolute;
}

.feedback-card {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: var(--black);
    border-radius: 50px 50px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    padding: 150px 80px;
    column-gap: 100px;
    row-gap: 65px;
}

.feedback-title > span:nth-child(4) {
    position: relative;
    z-index: 2;
}

.feedback-title > span:nth-child(4):before {
    display: inline;
    content: "";
    position: absolute;
    border-radius: 20px;
    width: 345px;
    height: 69px;
    transform: translate(-20px, 0) rotate(-0.5deg);
    flex-shrink: 0;
    background: var(--wine) 0 0 repeat;
    z-index: -1;
}

.feedback-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    max-width: 550px;
}

.feedback-form-input {
    display: flex;
    padding: 25px 30px 25px 50px;
    align-items: center;
    border-radius: 100px;
    gap: 20px;
    align-self: stretch;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    background: #CCCCCC;
    border: 2px solid transparent;
    transition: all 0.2s;
}

.feedback-form-input::placeholder {
    color: rgba(9, 9, 9, 0.60);
}

.feedback-form-input {
}

.validation {
    margin-top: 9px;
    position: absolute;
    opacity: 0;
    padding: 6px 10px;
    border-radius: 3px;
    background: var(--white);
    transition: opacity 0.3s;
    /*position: relative;*/
    width: fit-content;
}

.validation > svg {
    position: absolute;
    top: -9px;
    left: 30px;
}

.feedback-form-input:required:valid {
    background: var(--white);
    border: 2px solid var(--white);
}

.feedback-form-input:required:invalid:not(:placeholder-shown) {
    background: var(--white);
    border: 2px solid var(--red);
}

.feedback-form-input:required:invalid:not(:placeholder-shown) + .validation {
    opacity: 1;
}


.feedback-form-input:nth-child(4) {
    display: flex;
    height: 81px;
    padding: 20px 10px 20px 50px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: var(--brat);
    cursor: pointer;
    margin-top: 20px;
}

.feedback-form-input-icon {
    transition: all 0.3s;
    border: 2px solid var(--black);
    padding: 15px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
}

.feedback-form-input-icon > svg {
    height: 18px;
    width: 18px;
}
.feedback-form-input-icon > svg > path {
    stroke-width: 4px;
}

.feedback-form-input:nth-child(4):hover > .feedback-form-input-icon {
    transition: all 0.3s;
    border-radius: 50%;
    border: 2px solid var(--black);
    background: var(--black);
    color: var(--brat);
}

.feedback-form-politics{
    margin-top: -10px;
}


@media (max-width: 961px) {
    .feedback-title > span:nth-child(4):before {
        border-radius: 5px;
        width: 200px;
        height: 34px;
        transform: translate(-10px, 0) rotate(-0.5deg);
        flex-shrink: 0;
    }

    .feedback-card {
        padding: 60px 50px;
    }

    .feedback-form{
        max-width: 350px;
    }

    .feedback-form-input {
        padding: 20px 30px;
    }

    .feedback-form-input:nth-child(4){
        padding: 20px 20px 20px 30px;
        height: 66px;
    }

    .feedback-form-input-icon {
        transition: all 0.3s;
        border: 2px solid var(--black);
        padding: 15px;
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 50%;
    }

    .feedback-form-input-icon > svg {
        height: 14px;
        width: 14px;
    }


}
