.special {
    background: var(--white);
    height: fit-content;
    width: 100%;
    position: relative;
}

.special>svg{
    position: absolute;
}

.special-card {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: var(--wine);
    border-radius: 50px 50px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 60px 50px 100px 50px;
    gap: 40px;
    cursor: pointer;
    transition: all 0.3s;
}

.special-cards{
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 30px;
}

.special-card-header{
    display: flex;
}

.special-card-body{
    padding: 30px 20px 40px 40px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    gap:20px;
    max-width: 520px;
    width: 100%;
    height: 190px;
    background: var(--white);
    box-shadow: 4px 4px 0 0 var(--black);
}

.special-card-body.hovered{
    transition: all 0.3s;
    background-color: var(--brat);
    transform: rotate(-2deg);
}



@media (max-width:961px) {
    .special-card{
        padding: 60px 20px;
    }

    .special-card-body{
        height: 158px;
        max-width: 295px;
        min-width: 273px;
        width: 100%;
    }
    .special-card-body.hovered{
        /*height: 136px*/
    }
}


