.footer{
    background: var(--white);
    display: flex;
    flex-direction: column;
    padding: 70px 110px 50px 30px;
    gap: 140px;
    position: relative;
    min-height: 550px;
}

.footer > svg{
    position: absolute;
    z-index: 0;
}

.footer-up{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 40px;
    z-index: 1;
}

.footer-up-logo{
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer-up-menu{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 60px;
}

.footer-down{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 20px 0 50px;
    z-index: 1;
}

.footer-down-politics{
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.footer-down-politics > div:nth-child(1){
    text-decoration: underline;
    cursor: pointer;
}

.footer-up-menu-icons{
    gap: 10px;
    display: flex;
}

.footer-up-menu-icons > div{
    transition: background-color 0.3s;
    background-color: var(--black);
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 70px;
    height: 70px;
}
.footer-up-menu-icons > div:nth-child(1) > svg{
    width: 36px;
    height: 30px;
}
.footer-up-menu-icons > div:nth-child(2) > svg {
    width: 47px;
    height: 35px;
}

.footer-up-menu-icons > div:hover{
    background-color: var(--wine);
    border-radius: 35px;
    transition: background-color 0.3s;
}


@media (max-width:961px) {
    .footer{
        padding: 50px 17px 20px 10px;
    }
    .footer-down{
        gap: 40px;
        padding: 0 0 0 10px;
    }
    .footer-up-menu{
        padding-left: 30px;
    }
    .footer-down-copyr{
        width: 100%;
        text-align: right;
    }
    .footer-up-logo > img{
        width: 131px;
    }
    .footer-up-menu{
        gap: 15px;
    }
    .footer-up-logo{
        gap: 8px;
    }
    .footer-up-menu-icons > div{
        border-radius: 25px;
        width: 50px;
        height: 50px;
    }
    .footer-up-menu-icons > div:nth-child(1) > svg{
        width: 26px;
        height: 21px;
    }
    .footer-up-menu-icons > div:nth-child(2) > svg {
        width: 34px;
        height: 25px;
    }
}
