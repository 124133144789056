body {
  margin: 0;
  height: 100vh;
  background: var(--black);
}


:root{
  --black: #090909;
  --white: #FCFCFC;
  --gray: #D9D9D9;
  --brat: #CCF304;
  --wine: #8420E8;
  --bad-wine: #502B8C;
  --red: #F64D4D;
  --glass-wine: #47138A;
  --wine-black-grad: linear-gradient(#411091, #200C41, #090909);
  --header-back: rgba(9, 9, 9, 0.10);

}

