.app{
    position: relative;
    overflow-x: hidden;
    height: 100vh;
    user-select: none
}

.app-content{
    transition: filter 0.5s;
    filter: none;
}

.blur{
    transition: filter 0.5s;
    filter: none;
}


@media (max-width:961px) {
    .blur{
        filter: blur(4px);
    }
}
