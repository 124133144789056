.hello {
    height: 100vh;
    background: var(--wine-black-grad);
    user-select: none;
    min-height: 900px;
    position: relative;
}

.hello > svg{
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
}

.hello > .blick {
    width: 862px;
    opacity: 0;
    transition: opacity 0.3s;
    height: 396px;
    border-radius: 862px;
    background: rgba(252, 252, 252, 0.15);
    filter: blur(100px);
}


.hello-text {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 900px;
}


.hello-text-span-1 {
    position: relative;
    color: var(--black);
    border-radius: 20px;
    z-index: 3;
}

.hello-text-span-1:before {
    display: inline;
    content: "";
    position: absolute;
    width: 590px;
    height: 93px;
    border-radius: 20px;
    transform: translate(-10px, 7px) rotate(0.5deg);
    background: var(--brat) 0 0 repeat;
    z-index: -1;
}

.hello-text-span-2 {
    color: var(--wine);
}


.hello-for {
    position: absolute;
    right: 80px;
    top: 124px;
    gap: 2px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


.hello-for-item {
    padding: 5px 20px;
    border-radius: 100px;
    background-color: var(--glass-wine);
    width: fit-content;
}

.br-1 {
    display: none;
}

.hello-about {
    width: 615px;
}

.hello-button {
    padding: 10px 20px 10px 30px;
    border: 2px var(--brat) solid;
    border-radius: 100px;
    gap: 15px;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    width: fit-content;
    min-width: 263px;
    height: fit-content;
}

.hello-button:hover {
    background: var(--brat);
    cursor: pointer;
}

.hello-button-icon {
    border: 2px var(--brat) solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    transition: all 0.3s;
}

.hello-button:hover > .hello-button-icon {
    transition: all 0.3s;
    background: var(--black);
    border: 2px var(--brat) solid;
    color: var(--brat);
}

.hello-button-icon-arrow {
    margin: 12px;
}

.hello-footer {
    position: absolute;
    bottom: 130px;
    display: flex;
    justify-content: space-between;
    padding: 0 80px;
    width: calc(100% - 160px);
    gap: 10px;
}

@media (max-width: 961px) {
    .hello-text-span-1:before {
        width: 290px;
        height: 40px;
        border-radius: 5px;
        transform: translate(-5px, 3px) rotate(0.5deg);
    }

    .hello-text-span-2{
        position: relative;
        z-index: 5;
    }

    .hello-for {
        right: 20px;
        top: 90px;
    }

    .br-1 {
        display: block;
    }

    .br-2 {
        display: none;
    }
    .hello-footer {
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        gap: 50px;
        bottom: 50px;
    }
    .hello-about{
        text-align: center;
        min-width: 0;
        max-width: 335px;
    }
    .hello-text{
        width: 360px;
    }
    .hello > .blick{
        width: 335px;
        height: 481px;
        flex-shrink: 0;
        border-radius: 481px;
        background: rgba(252, 252, 252, 0.15);
        filter: blur(100px);
    }
}
