.description{
    background: var(--black);
    user-select: none;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom:60px;

}
.description > svg{
    position: absolute;
    left: 0;
    top:0;
    opacity: 0;
    transition: opacity 0.3s 0.2s;
}

.description-card-main-text{
    text-align: center;
}

.description-card{
    padding: 50px 62px 76px 58px;
    display: flex;
    gap: 50px;
    flex-direction: column;
    border-radius: 40px;
    background: var(--wine);
    margin: 40px 50px 50px 50px;
    height: fit-content;
    max-width: 1080px;
}

.description-card-benefits{
    display: flex;
    gap: 20px;
}
.description-card-benefit{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.description-sponsors{
    display: flex;
    justify-content: center;
    gap: 25px;
    align-items: center;
    margin: 20px 0;
    z-index: 1;

}


@media (max-width: 1140px){
    .description-card-main-text > .br-2{
        display: none;
    }
}

@media (max-width:961px) {
    .description{
        margin-bottom: 60px;
    }
    .description-card{
        padding: 50px 20px;
        gap: 40px;
    }
    .description-card-benefits{
        flex-direction: column;
    }
    .description-card{
        margin: 50px 20px;
    }
    .description-sponsors{
        margin: 10px 0;
    }
}


