
.ticker {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    background: var(--brat);
    padding: 10px 0;
}

.ticker .scroll {
    width: 100%;
    display: flex;
}

.scroll-item {
    height: 46px;
    white-space: nowrap;
    animation: animate-ticker 60s linear infinite;
    animation-delay: 0s;
    color: var(--black);
    font-family: Inter, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; /* 76.667% */
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.scroll-item > div{
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
}

@keyframes animate-ticker {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}


@media (max-width: 851px) {
    .scroll-item {
        height: 30px;
        gap: 8px;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        text-transform: uppercase;
    }
    .scroll-item > div{
        gap: 8px;
    }

    .scroll-item-icon{
        width: 38px;
        height: 38px;
    }

}
