.teammate{
    height: 400px;
    width: 330px;
    background: var(--white);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 10px;
}

.teammate-img-back{
    border-radius: 20px 20px 0 0;
    height: 270px;
    background: linear-gradient(0deg, #1F0935 0%, #8420E8 109.5%);
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
}
.teammate-img{
    height: 254px;
    width: auto;
}

.teammate-statistics{
    display: flex;
    gap: 10px;
    justify-content: center;
}

.teammate-statistics-text{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}


.teammate-statistics-yt{
    background: var(--black);
    border-radius: 50%;
    display: flex;
    width: 25px;
    height: 17px;
    padding: 9px 5px 9px 5px;
    justify-content: center;
    align-items: center;
}
.teammate-statistics-tw{
    background: var(--black);
    border-radius: 50%;
    display: flex;
    width: 19px;
    height: 22px;
    padding: 7px 7px 5px 9px;
    justify-content: center;
    align-items: center;
}
.teammate-statistics-tg{
    background: var(--black);
    border-radius: 50%;
    display: flex;
    width: 21px;
    height: 24px;
    padding: 6px 8px 4px 6px;
    justify-content: center;
    align-items: center;
}

@media (max-width:961px) {

}
